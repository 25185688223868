@import "../../style-p8";

.section__candidature__title {
  background: firebrick;
  padding: 10px;
  width: max-content;
  color: white;
  border-radius: 4px;
  //font-family: "Titillium Web", serif;
}
.mandatory-field {
  font-size: 1.2em;
  color: red;
}

$custom-first : #669966;
$custom-color-first : #013b01;
$custom-second : #454545;
$custom-color-second: #e3e3e3;
$custom-third : #b15252;
$custom-color-third: #fcfcfc;
$custom-fourth : #6b97b8;
$custom-color-fourth: #002d52;
$white: #FFF;

.mat-raised-button{
  &.mat-custom-first {
    background: $custom-first;
    color: $custom-color-first;
  }
  &.mat-custom-second {
    background: $custom-second;
    color: $custom-color-second;
  }
  &.mat-custom-third{
    background: $custom-third;
    color: $custom-color-third;
  }
  &.mat-custom-fourth {
    background: $custom-fourth;
    color: $custom-color-fourth;
  }
}

.mat-icon-button {
  &.mat-custom-first {
    color: $custom-first;
  }
  &.mat-custom-second {
    color: $custom-second;
  }
  &.mat-custom-third {
    color: $custom-third;
  }
  &.mat-custom-fourth {
    color: $custom-fourth;
  }
}


