@use '@angular/material' as mat;
@import '@angular/material/theming';

/**
  Inclure dans index.html de l'application pour la fonte Poppins:
  <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
 */
$custom-typography: mat.define-legacy-typography-config(
  // $font-family:   'Roboto, "Helvetica Neue", sans-serif',
  $font-family: '"Poppins", "Roboto", "Helvetica Neue", sans-serif',
  $display-4:     mat.define-typography-level(56px, 56px, 300),
  $display-3:     mat.define-typography-level(45px, 48px, 400),
  $display-2:     mat.define-typography-level(34px, 40px, 400),
  $display-1:     mat.define-typography-level(30px, 37px, 300),
  $headline:      mat.define-typography-level(24px, 32px, 400),
  $title:         mat.define-typography-level(20px, 32px, 500),
  $subheading-2:  mat.define-typography-level(18px, 30px, 400),
  $subheading-1:  mat.define-typography-level(16px, 28px, 400),
  $body-2:        mat.define-typography-level(14px, 24px, 500),
  $body-1:        mat.define-typography-level(14px, 24px, 400),
  $caption:       mat.define-typography-level(13px, 20px, 400),
  $button:        mat.define-typography-level(14px, 14px, 500),
    // Line-height must be unit-less fraction of the font-size.
  $input:         mat.define-typography-level(inherit, 1.125, 400)
);


//@include angular-material-typography($custom-typography);
@include mat.legacy-typography-hierarchy($custom-typography);
@include mat.all-component-typographies($custom-typography);
@include mat.legacy-core()
